<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>
    <template>
      <v-form>
        <template v-if="!edit">
          <v-text-field v-model="state.name" label="Item name">
            {{ state.name }}
          </v-text-field>
          <type-selector v-model="state.type" label="Variable type" />
          <v-text-field v-model="state.units" label="Units">
            {{ state.units }}
          </v-text-field>
          <v-text-field v-model="state.mask" label="Mask">
            {{ state.mask }}
          </v-text-field>
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          >
            {{ state.description }}
          </v-textarea>
          <v-text-field v-model="state.alertCount" label="Alert count">
            {{ state.alertCount }}
          </v-text-field>
        </template>
        <form-subheader title="Value" />
        <v-select
          v-model="state.sourceId"
          label="Source name"
          item-value="id"
          item-text="name"
          :items="sources"
          clearable
          @click:clear="clear({ sourceId: '', propertyId: null })"
        />
        <v-select
          v-model="state.propertyId"
          label="Property name"
          item-value="id"
          item-text="name"
          :items="properties(state.sourceId)"
          clearable
          @click:clear="clear({ propertyId: null })"
        />
        <template v-if="state.optionalProperties.length">
          <form-subheader title="Optional properties" />
          <v-text-field
            v-for="item in state.optionalProperties"
            :key="item.id"
            v-model="item.value"
            :label="item.property"
          />
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import TypeSelector from '@/components/common/TypeSelector';
import { ref, onMounted, computed, nextTick } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectPropertyService, objectService } from '@/modules/common/api';
import { linkedObjectsService } from '@/modules/linked-objects/api';
import { prepareObject, typeCast } from '@/provider/utils';
import { monitoringItemService } from '@/modules/object-types/api';
import { abcSort } from '@/compositions/sortBy';
export default {
  name: 'MonitoringItemEdit',
  components: {
    Layout,
    TypeSelector
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    monitoringItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      sourceId: '',
      propertyId: null,
      name: '',
      type: '',
      units: '',
      mask: '',
      description: '',
      alertCount: 1,
      optionalProperties: []
    });
    const sources = ref([]);
    const monitoringItem = ref({});
    const edit = computed(() => props.monitoringItemId);
    const title = computed(
      () => `${edit.value ? 'Edit' : 'Add'} monitoring item`
    );

    const handleSubmit = async () => {
      if (!edit.value) {
        const schemaId = await monitoringItemService.fetchBaseMonitoringItemSchemaId();

        //create
        const res = await objectService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId
        });
        monitoringItem.value = prepareObject(res?.data?.createObject?.object);

        //link
        await objectService.link(props.objectId, monitoringItem.value.id);

        // set properties
        await objectPropertyService.updateValues([
          {
            id: monitoringItem.value.commonType.id,
            value: state.value.type
          },
          {
            id: monitoringItem.value.commonUnits.id,
            value: state.value.units
          },
          {
            id: monitoringItem.value.commonMask.id,
            value: state.value.mask
          },
          {
            id: monitoringItem.value.commonAlertCount.id,
            value: state.value.alertCount
          },
          {
            id: monitoringItem.value.infoName.id,
            value: state.value.name
          }
        ]);
      }

      // set properties
      await objectPropertyService.update(monitoringItem.value.stateValue.id, {
        linkedPropertyId: state.value.propertyId
      });

      // update optional properties
      await objectPropertyService.updateValues(
        state.value.optionalProperties.map(property => ({
          id: property.id,
          value: typeCast(property.type, property.value)
        }))
      );

      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    const properties = sourceId => {
      const source = sources.value.find(source => source.id === sourceId);
      const schemaProperties = source?.schema?.schemaProperties || [];
      const namesMap = schemaProperties.reduce((acc, cur) => {
        acc[cur.property] = cur.description || cur.property;
        return acc;
      }, {});

      return source?.objectProperties
        .map(i => ({ ...i, name: namesMap[i.property] }))
        .sort((a, b) => abcSort(a?.name, b?.name));
    };

    const clear = obj => {
      nextTick(() => {
        state.value = {
          ...state.value,
          ...obj
        };
      });
    };

    onMounted(async () => {
      sources.value = await linkedObjectsService.fetch(props.objectId);

      if (edit.value) {
        monitoringItem.value = prepareObject(
          await objectService.fetch(props.monitoringItemId)
        );

        const linkedPropertyId =
          monitoringItem.value.stateValue?.linkedPropertyId;

        if (linkedPropertyId) {
          const source = sources.value.find(source =>
            source.objectProperties.some(
              property => property.id === linkedPropertyId
            )
          );
          if (source) {
            state.value.sourceId = source.id;
            state.value.propertyId = linkedPropertyId;
          }
        }

        const optionalProperties = Object.keys(monitoringItem.value)
          .filter(key => monitoringItem.value[key]?.groupName === 'Custom')
          .map(key => monitoringItem.value[key]);

        state.value = {
          ...state.value,
          name: monitoringItem.value.name,
          type: monitoringItem.value.commonType.value,
          units: monitoringItem.value.commonUnits.value,
          mask: monitoringItem.value.commonMask.value,
          description: monitoringItem.value.description,
          alertCount: monitoringItem.value.commonAlertCount.value,
          optionalProperties
        };
      }
    });

    return {
      state,
      sources,
      properties,
      loading,
      submit,
      clear,
      title,
      edit
    };
  }
};
</script>
